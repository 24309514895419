<template>
  <div v-loading="dataLoading" class="home-page">
    <div class="app-container">
      <!-- Layout -->
      <el-row :gutter="20" class="row-bg" justify="center">
        <!-- Content -->
        <el-col :sm="24" :md="20">
          <el-form ref="passwordForm" :model="passwordForm" :rules="passwordRules">
            <!-- Row 1 -->
            <el-row :gutter="20" justify="center">
              <el-col :md="13">
                <div class="brand-color mb-5">現在のパスワード</div>
                <el-form-item prop="current_password">
                  <el-input
                    v-model="passwordForm.current_password"
                    size="small"
                    tabindex="1"
                    type="password"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <!-- Row 2 -->
            <el-row :gutter="20" justify="center">
              <el-col :md="13">
                <div class="brand-color mb-5">新しいパスワード</div>
                <el-form-item prop="new_password">
                  <el-input
                    v-model="passwordForm.new_password"
                    size="small"
                    tabindex="1"
                    type="password"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10" class="mt-50 mb-50 text-center">
              <el-col>
                <el-button
                  type="primary"
                  style="width:30%;margin-bottom:30px;"
                  @click="handleSubmit"
                >保存</el-button>
                <el-button
                  type="info"
                  style="width:30%;margin-bottom:30px;"
                  @click="handleCancel"
                >キャンセル</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { updatePassword } from '@/api/profile'

export default {
  name: 'ChangePassword',
  data() {
    return {
      dataLoading: false,
      passwordForm: {
        current_password: '',
        new_password: ''
      },
      passwordRules: {
        current_password: [
          { required: true, trigger: 'blur', message: '「現在のパスワード」を入力してください' }
        ],
        new_password: [
          { required: true, trigger: 'blur', message: '「新しいパスワード」を入力してください' },
          { min: 8, trigger: 'blur', message: 'パスワードは8文字以上' }
        ]
      }
    }
  },
  methods: {
    updateData() {
      this.dataLoading = true
      updatePassword(this.passwordForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に更新されました',
          type: 'success',
          duration: 2000
        })

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
          this.handleCancel()
        }, 0.1 * 1000)
      }).catch((response) => {
        this.dataLoading = false
      })
    },
    handleSubmit() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.updateData()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCancel() {
      this.$router.push('/profile')
    }
  }
}
</script>
